import { environment } from '@environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

const apiRoot = `${environment.keycloak.oauthServerHost}/realms/${environment.keycloak.oauthRealm}`;

export const AuthApiRoutes = {
  RealmRoot: `${apiRoot}`,
  UpdateUser: `${apiRoot}/account`,
  TokenEndpoint: `${apiRoot}/protocol/openid-connect/token`,
  AuthEndpoint: `${apiRoot}/protocol/openid-connect/auth`,
};

export const authCodeFlowConfig: AuthConfig = {
  issuer: AuthApiRoutes.RealmRoot,
  tokenEndpoint: AuthApiRoutes.TokenEndpoint,
  redirectUri: `${window.location.origin}/login`,
  clientId: environment.keycloak.oauthClientId,
  responseType: environment.keycloak.responseType,
  scope: environment.keycloak.scope,
  strictDiscoveryDocumentValidation: false,
  showDebugInformation: true,
  useSilentRefresh: false,
  silentRefreshRedirectUri: `${window.location.origin}/assets/silent-refresh.html`,
  disableAtHashCheck: true,
  oidc: true,
  requireHttps: false,
};
