import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthApiRoutes } from '@config/auth.config';
import { environment } from '@environments/environment';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { PAGE_ROUTES } from '@enums/page-routes.enum';
import { AUTH_STORAGE_ITEMS, LOCAL_STORAGE } from '@enums/local-storage.enum';
import { UnsubscribeBase } from '@core-utils';

interface RealmAccess {
  roles?: string[];
}

interface TokenClaims {
  realm_access?: RealmAccess;
  given_name?: string;
  family_name?: string;
  email?: string;
  onboarded?: boolean;
  [key: string]: unknown;
}

@Injectable()
export class AuthApiService extends UnsubscribeBase {
  isLoading = signal<boolean>(false);

  constructor(
    private oauthService: OAuthService,
    private http: HttpClient,
    private router: Router,
  ) {
    super();
  }

  initLoginFlow() {
    this.oauthService.initLoginFlow();
  }

  updateExpireToken() {
    this.addSubscription = this.oauthService.events.subscribe((e) => {
      if (e.type === 'token_received') {
        this.hasValidAccessToken();
      }
      if (e.type === 'token_refresh_error') {
        AUTH_STORAGE_ITEMS.map((item: string) => {
          sessionStorage.removeItem(item);
        });
        this.initLoginFlow();
      }
    });
  }

  refreshTokenAfterSleep() {
    document.addEventListener('visibilitychange', async () => {
      if (
        document.visibilityState === 'visible' &&
        !this.hasValidAccessToken() &&
        !this.router.url.split('/').includes(PAGE_ROUTES.AUTH)
      ) {
        this.oauthService.initLoginFlow();
      }
    });
  }

  hasValidAccessToken(): boolean {
    const token = this.oauthService.getAccessToken();
    if (!token) {
      return false;
    }

    // const tokenClaims = this.decodeJwt(token);
    // const expirationTime = (tokenClaims['exp'] as number) * 1000;
    // const currentTime = Date.now();

    const expirationTime = Number(
      sessionStorage.getItem(LOCAL_STORAGE.TOKEN_EXPIRES_AT),
    );
    const currentTime = Number(
      sessionStorage.getItem(LOCAL_STORAGE.TOKEN_STORED_AT),
    );

    return currentTime < expirationTime;
  }

  login() {
    this.oauthService.initLoginFlow();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  get skipOnboarding(): boolean {
    const claims = this.getTokenClaims();

    if (!this.isClaimsValid(claims)) {
      this.oauthService.initLoginFlow();
      return false;
    }

    const isNonOnBoardableUser = this.hasRole(
      environment.keycloak.superAdminRole,
      claims,
    );

    return isNonOnBoardableUser || claims.onboarded === true;
  }

  logout() {
    this.oauthService.logOut();
  }

  async finalizeOnboardingProcess(): Promise<void> {
    try {
      await this.setOnboardingValue(true);
      console.log('Onboarding completed and token refreshed successfully.');
    } catch (error) {
      console.error('Error during onboarding process:', error);
      throw error;
    }
  }

  async resetOnboardingParam(): Promise<void> {
    try {
      await this.setOnboardingValue(false);
      console.log('Onboarding param switched OFF.');
      this.router.navigate(['/' + PAGE_ROUTES.CREATE_ACCOUNT]).then();
    } catch (error) {
      console.error('Error during switching process:', error);
      throw error;
    }
  }

  private async setOnboardingValue(value: boolean): Promise<void> {
    const claims = this.getTokenClaims();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken}`,
    });

    const body = {
      firstName: claims.given_name,
      lastName: claims.family_name,
      email: claims.email,
      attributes: {
        onboarded: value,
      },
    };

    try {
      await lastValueFrom(
        this.http.post(AuthApiRoutes.UpdateUser, body, { headers }),
      );
      await this.oauthService.refreshToken().then(() => {
        this.router.navigate(['/']).then();
      });
    } catch (error) {
      console.error('Error during onboarding process:', error);
      throw error;
    }
  }

  private getTokenClaims(): TokenClaims {
    return this.decodeJwt(this.accessToken);
  }

  private decodeJwt(token: string): TokenClaims {
    if (!token) return {};

    const parts = token.split('.');
    this.validateJwtParts(parts);

    return JSON.parse(window.atob(parts[1]));
  }

  private validateJwtParts(parts: string[]): void {
    if (parts.length !== 3) {
      throw new Error('JWT does not have 3 parts');
    }
  }

  private hasRole(role: string, claims: TokenClaims): boolean {
    return claims.realm_access?.roles?.includes(role) ?? false;
  }

  private isClaimsValid(claims: TokenClaims): boolean {
    const { realm_access, given_name, family_name, email } = claims;
    return Boolean(realm_access?.roles && given_name && family_name && email);
  }
}
